import React, { useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import {
    Avatar,
    Box,
    ButtonBase,
    Hidden,
    Menu,
    MenuItem,
    Typography,
    makeStyles,
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import { CapitalizeNames } from 'src/utils/capitalize';
import { useTranslation } from 'react-i18next';
import { isCarone } from 'src/utils/AuthRoles';
import useNotification from 'src/hooks/useNotification';

const useStyles = makeStyles((theme) => ({
    avatar: {
        height: 32,
        width: 32,
        marginRight: theme.spacing(1),
    },
    popover: {
        width: 200,
    },
}));

const Account = () => {
    const classes = useStyles();
    const ref = useRef(null);
    const { t } = useTranslation();
    const { user } = useAuth();
    const [isOpen, setOpen] = useState(false);
    const { clearNotificationState } = useNotification();

    const { logout } = useAuth();
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = () => {
        let lenguaje = localStorage.getItem('language');
        let menu = localStorage.getItem('typeMenu');
        let lan = localStorage.getItem('i18nextLng');
        let token = localStorage.getItem('token');
        let settings = localStorage.getItem('settings');

        localStorage.clear();

        localStorage.setItem('language', lenguaje);
        localStorage.setItem('typeMenu', menu);
        localStorage.setItem('i18nextLng', lan);
        localStorage.setItem('token', token);
        localStorage.setItem('settings', settings);

        logout();
    };

    useEffect(() => {
        return () => clearNotificationState();
        //eslint-disable-next-line
    }, []);

    if (!localStorage.getItem('token')) {
        return <Redirect to="/" />;
    }

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                component={ButtonBase}
                onClick={handleOpen}
                ref={ref}
            >
                <Avatar
                    alt="User"
                    className={classes.avatar}
                    src={
                        user && user.image
                            ? `https://automotive-api.s3.us-east-2.amazonaws.com/${user.image}`
                            : ''
                    }
                />
                <Hidden smDown>
                    <Typography variant="h6" color="inherit">
                        {user ? CapitalizeNames(user.name) : ''}
                    </Typography>
                </Hidden>
            </Box>
            <Menu
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                keepMounted
                PaperProps={{ className: classes.popover }}
                getContentAnchorEl={null}
                anchorEl={ref.current}
                open={isOpen}
            >
                <MenuItem component={RouterLink} to="/account">
                    {t('Account.Account')}
                </MenuItem>
                {user && user.tier && !isCarone(user.tier._id) && (
                    <MenuItem component={RouterLink} to="/reviews/recordings">
                        {t('Navbar.Recordings')}
                    </MenuItem>
                )}
                <MenuItem onClick={handleLogout}>
                    {t('Account.Logout')}
                </MenuItem>
            </Menu>
        </>
    );
};

export default Account;

import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import routes, { renderRoutes } from 'src/routes';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

import {
    WarboxProvider,
    MuiThemeProvider,
    NotistackProvider,
} from 'src/providers';
import './styles/all.css';

const history = createBrowserHistory();

const App = () => {
    return (
        <MuiThemeProvider>
            <NotistackProvider>
                <Router history={history}>
                    <WarboxProvider>
                        <GlobalStyles />
                        <ScrollReset />
                        <GoogleAnalytics />
                        {renderRoutes(routes)}
                    </WarboxProvider>
                </Router>
            </NotistackProvider>
        </MuiThemeProvider>
    );
};

export default App;

export const DIGITAL_AREA = 'digital';
export const PREOWNED_AREA = 'preowned';
export const SHOWROOM_AREA = 'showroom';
export const BDC_AREA = 'bdc';
export const SERVICE_AREA = 'servicio';
export const HYP_AREA = 'hyp';
export const SPARE_PARTS_AREA = 'refacciones';
export const FLEET_AREA = 'fleet';
export const RH_AREA = 'rh';
export const COMPLAINTS_AREA = 'complaints';

export const USER_AREAS_ARRAY = [
    DIGITAL_AREA,
    SHOWROOM_AREA,
    BDC_AREA,
    SERVICE_AREA,
    HYP_AREA,
    SPARE_PARTS_AREA,
    FLEET_AREA,
    RH_AREA,
    COMPLAINTS_AREA,
];

export type TUserAreas = (typeof USER_AREAS_ARRAY)[number];

export const NEW_CARTYPE = 'nuevo';
export const PREOWNED_CARTYPE = 'seminuevo';
export const BOTH_DIGITAL_CARTYPE = 'ambos';
export const BDC_CARTYPE = 'bdc';
export const SERVICE_CARTYPE = 'servicio';
export const HYP_CARTYPE = 'hyp';
export const SPARE_PARTS_CARTYPE = 'refacciones';

export const USER_CAR_TYPES_ARRAY = [
    NEW_CARTYPE,
    PREOWNED_CARTYPE,
    BOTH_DIGITAL_CARTYPE,
];

export type TUserCarType = (typeof USER_CAR_TYPES_ARRAY)[number];

export const USER_LEAD_TYPE_LEAD = 'lead';
export const USER_LEAD_TYPE_SHOWROOM = 'showroom';
export const USER_LEAD_TYPE_ALL = 'all';

export const USER_LEAD_TYPES_ARRAY = [
    USER_LEAD_TYPE_LEAD,
    USER_LEAD_TYPE_SHOWROOM,
    USER_LEAD_TYPE_ALL,
];

export type TUserLeadTypes = (typeof USER_LEAD_TYPES_ARRAY)[number];
